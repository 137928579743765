var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("gov-error-summary", { attrs: { title: "Invalid rows" } }, [
    _c(
      "p",
      [
        _c("gov-hint", [
          _vm._v(
            "Scroll the table left and right to see all columns. Fix the listed\n      errors and resubmit. Some large text fields have been shortened for\n      display, indicated by (...)."
          )
        ])
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "responsive-table-wrapper" },
      [
        _c(
          "gov-table",
          [
            _c(
              "template",
              { slot: "header" },
              [
                _c(
                  "gov-table-row",
                  [
                    _vm._l(_vm.fields, function(field, index) {
                      return _c(
                        "gov-table-header",
                        { key: "OrganisiationImportErrorHeader-" + index },
                        [
                          _vm._v(
                            "\n            " + _vm._s(field) + "\n          "
                          )
                        ]
                      )
                    }),
                    _c("gov-table-header", [_vm._v(" Duplicates ")])
                  ],
                  2
                )
              ],
              1
            ),
            _c(
              "template",
              { slot: "body" },
              _vm._l(_vm.combinedRows, function(error, rowIndex) {
                return _c(
                  "gov-table-row",
                  { key: "OrganisiationImportErrorRow-" + rowIndex },
                  [
                    _vm._l(_vm.fields, function(field, index) {
                      return _c(
                        "gov-table-cell",
                        { key: "OrganisiationImportErrorField-" + index },
                        [
                          _vm._v(
                            _vm._s(_vm.trimString(error.row[index])) +
                              "\n            "
                          ),
                          error.errors && error.errors[index]
                            ? _c(
                                "gov-error-message",
                                {
                                  attrs: {
                                    for:
                                      "OrganisiationImportErrorField-" + index
                                  }
                                },
                                [_vm._v(_vm._s(error.errors[index][0]))]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    }),
                    _c(
                      "gov-table-cell",
                      [
                        error.originals
                          ? _c(
                              "gov-list",
                              _vm._l(error.originals, function(
                                original,
                                index
                              ) {
                                return _c(
                                  "li",
                                  {
                                    key: "OrganisiationImportDuplicate-" + index
                                  },
                                  [
                                    _c(
                                      "gov-grid-row",
                                      [
                                        _c(
                                          "gov-grid-column",
                                          { attrs: { width: "two-thirds" } },
                                          [
                                            _c(
                                              "gov-link",
                                              {
                                                attrs: {
                                                  to: {
                                                    name: "organisations-show",
                                                    params: {
                                                      organisation: original.id
                                                    }
                                                  }
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                      " +
                                                    _vm._s(original.name) +
                                                    "\n                    "
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        ),
                                        _c(
                                          "gov-grid-column",
                                          { attrs: { width: "one-third" } },
                                          [
                                            original.id
                                              ? _c(
                                                  "gov-button",
                                                  {
                                                    attrs: {
                                                      success: !!original.ignored
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.ignoreDuplicate(
                                                          original
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        !!original.ignored
                                                          ? "Ignored"
                                                          : "Ignore"
                                                      )
                                                    )
                                                  ]
                                                )
                                              : _c("gov-error-message", [
                                                  _vm._v("Repeated row")
                                                ])
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              }),
                              0
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ],
                  2
                )
              }),
              1
            )
          ],
          2
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
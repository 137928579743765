var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ck-file-input", {
    ref: "fileInput",
    attrs: {
      value: _vm.spreadsheet,
      id: "spreadsheet",
      label: "Upload Spreadsheet (.xls or .xlsx)",
      accept: "*",
      error: _vm.errors.get("invalidRows") || _vm.errors.get("spreadsheet"),
      hint: _vm.feedback
    },
    on: {
      input: function($event) {
        return _vm.onInput(
          "spreadsheet",
          $event === null ? null : $event.content
        )
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }